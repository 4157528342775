/* DataTableTop30.css */


/* Container styling */
.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
  }

/* Full width table styling */
.full-width-table {
    width: 80%;
  }
  
  /* Half width table styling */
  .half-width-table {
    width: 50%;
  }


/* Table styling */
table {
    border-collapse: collapse;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
  }
  
  /* Header row styling */
  thead th {
    background-color: #4cbb17;
    color: #ffffff;
    font-weight: bold;
    padding: 12px;
    text-align: left;
    border: none;
  }
  
  /* Table row styling */
  tbody tr {
    background-color: #ffffff;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9fff9;
  }
  
  /* Table cell styling */
  td {
    padding: 12px;
    border: 1px solid #dddddd;
    text-align: left;
  }
    
  /* Hover effect for cells */
  td:hover {
    background-color: #a6d785;
  }

  /* Flexbox row styling for StrategyTable */
.flex-row {
    display: flex;
  }
  
  .flex-row td:first-child {
    flex: 0 1 auto; /* Take only as much space as needed */
  }
  
  .flex-row td:last-child {
    flex: 1 1 0%; /* Take the remaining space */
  }